<template>
  <section class="magic-happens-section">
    <v-container>
      <h2 class="section-title">Where the magic happens...</h2>

      <div class="magic-happens">
        <div class="magic-happens-img">
          <client-only>
            <img v-lazy="'/img/magic-happens.png'" width="365" height="307" alt="">
          </client-only>
        </div>


        <div class="magic-happens-info">
          <p><strong>You might be wondering - how does Tripening manage to find all these cool
            activities?</strong></p>
          <p>Well, we have to admit we rely on technology quite a bit. Our engineers tell us
            they've created a "proprietary software
            that processes thousands of qualitative and
            quantitative data at a time, to find the highest-reviewed experiences and
            travel flight deals per location" - or whatever that means.
            (We trust they know what they're talking about)</p>
          <p class="mb-0 additional-info">Either way, our users are happy. Which means we're happy!</p>
        </div>
      </div>
    </v-container>
  </section>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  methods: {

  }
}
</script>

<style src="./magic-happens.scss" lang="scss"></style>
