<template>
  <div class="covered-section">
    <div class="section">
      <div class="page-container">
        <div class="section-title section-title--big">
          <h2>
            We've got you covered from <br> start to finish!
          </h2>
        </div>
        <div class="covered">
          <div class="covered__row covered__row--reverse">
            <div>
              <img v-lazy="'/img/covered/covered-ill.svg'" alt="">
            </div>
            <div>
              First book great deals on flights and accommodation, then check out what you'll do when
              you get there.
              <p>
                <strong>Just click on any of our tours to know:</strong>
              </p>
              <ul>
                <li>
                  About the attraction, the group tour operator, and what you can expect
                </li>
                <li>
                  On the day
                </li>
                <li>
                  Popular, must-see highlights as recommended by other visitors
                </li>
                <li>
                  How much time to set aside
                </li>
                <li>
                  Location of your activity
                </li>
                <li>
                  Any extras included, like free lunch or sports gear, and any travel
                </li>
                <li>
                  Discounts
                </li>
              </ul>
            </div>
          </div>
          <div class="covered__row">
            <v-btn depressed block x-large color="primary" class="covered__btn" @click.prevent="goToSearch()">Search Destination</v-btn>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="page-container">
        <div class="covered">
          <div class="covered__row">
            <div>
              <img v-lazy="'/img/covered/all-about.svg'" alt="">
            </div>
            <div>
              <h2>
                What we're all about
              </h2>
              We're here because we saw a problem.

              <p>
                <strong>
                  We noticed people got stressed booking their vacations. And when we looked into it a little
                  more, we found out stuff like this:
                </strong>
              </p>
              <ul>
                <li>
                  The average American family spends 20 hours booking a trip, and!
                </li>
                <li>
                  They visit almost 40 different sites to do it!
                </li>
              </ul>
              <p>
                So we decided to come up with a solution: a website where all the best flights, hotels, and
                activities are already hand-picked for you. <br>
                That way, instead of visiting 40 sites, you visit 1. And instead of spending 20 hours booking a
                trip, you spend 30 minutes. We'd call that a win-win!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style src="./covered.scss" lang="scss"></style>

<script>
export default {
  methods: {
    goToSearch() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    },
  }
}
</script>
