<template>
  <section class="easy-booking-section">
    <v-container>
      <h2 class="section-title">Easy-Peasy Booking</h2>

      <v-row>
        <v-col cols="12" sm="4">
          <div class="booking-box">
            <div class="icon">
              <client-only><img v-lazy="'/img/booking-1.svg'" width="76" height="80" alt=""></client-only>
            </div>
            <div class="text">
              No more messy Google docs, no more disorganized calendars - get all the details you're looking for, in easy-to-consume format, with no time investment on your part
            </div>

            <div class="booking-separator">
              <span class="circle"></span>
              <span class="circle"></span>
              <span class="circle"></span>
              <span class="circle"></span>
              <FontAwesomeIcon :icon="['fal', 'arrow-right-long']" class="arrow"/>
            </div>
          </div>
        </v-col>

        <v-col cols="12" sm="4">
          <div class="booking-box">
            <div class="icon">
              <client-only><img v-lazy="'/img/booking-2.svg'" width="66" height="80" alt=""></client-only>
            </div>
            <div class="text">
              Book through Tripening tours and travels, and get all the information you need in one place.
            </div>

            <div class="booking-separator">
              <span class="circle"></span>
              <span class="circle"></span>
              <span class="circle"></span>
              <span class="circle"></span>
              <FontAwesomeIcon :icon="['fal', 'arrow-right-long']" class="arrow"/>
            </div>
          </div>
        </v-col>

        <v-col cols="12" sm="4">
          <div class="booking-box pb-0">
            <div class="icon">
              <client-only><img v-lazy="'/img/booking-3.svg'" width="80" height="80" alt=""></client-only>
            </div>
            <div class="title">
              Easy, peasy, done.
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  methods: {

  }
}
</script>

<style src="./easy-booking.scss" lang="scss"></style>
