// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/img/check-bullet.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".covered-section .section{border-top:.0666666667rem solid #eff3fc}.covered__row{display:flex;position:relative;overflow:hidden}.covered__row>div{width:50%;padding:0 3.3333333333rem}@media only screen and (max-width:767px){.covered__row>div{width:100%;margin-bottom:3.3333333333rem;padding:0;font-size:.9333333333rem}}.covered__row--reverse{flex-direction:row-reverse}.covered__row img{margin:0 auto;display:block}.covered__row p{margin:1.6666666667rem 0}@media only screen and (max-width:767px){.covered__row{flex-direction:column}}.covered__btn{width:16rem;min-width:16rem!important;max-width:16rem;height:4rem;margin:0 auto;border-radius:.2666666667rem}@media only screen and (max-width:767px){.covered__btn{width:100%;max-width:100%}}.covered ul{list-style:none;padding-left:0;margin:1.6rem 0}.covered ul li{line-height:1;padding:.3333333333rem 0}.covered ul li:before{content:\"\";display:inline-block;width:1.0666666667rem;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:contain;background-repeat:no-repeat;margin-right:1.0666666667rem;height:1.0666666667rem;float:left}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
