// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/img/dot.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".crossing-off{margin-top:4rem}.crossing-off h3{font-weight:800;font-size:2.4rem;line-height:2.9333333333rem;text-transform:capitalize;color:#424d63;padding-bottom:2.1333333333rem}@media only screen and (max-width:767px){.crossing-off h3{font-size:2.0666666667rem}}.crossing-off a{text-decoration:none;font-weight:700}.crossing-off__row{display:flex;position:relative;overflow:hidden}.crossing-off__row>div{width:50%;padding:0 3.3333333333rem}@media only screen and (max-width:767px){.crossing-off__row>div{width:100%;margin-bottom:3.3333333333rem;padding:0}}.crossing-off__row--reverse{flex-direction:row-reverse}@media only screen and (max-width:767px){.crossing-off__row{flex-direction:column}}.crossing-off__article{display:flex;flex-direction:column;justify-content:center}.crossing-off__article .summ{color:#09b5ff;font-weight:700}.crossing-off__dash{position:absolute;height:100%;border-left:.0933333333rem dashed #d1e1e8;width:.0666666667rem!important;left:50%;padding:0!important}@media only screen and (max-width:767px){.crossing-off__dash{display:none}}.crossing-off__dash--top{top:50%}.crossing-off__dash--top:before{top:-1.6666666667rem}.crossing-off__dash--middle:after,.crossing-off__dash--top:before{content:\"\";background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 50% no-repeat;width:2rem;height:2rem;position:absolute;left:-1rem}.crossing-off__dash--middle:after{top:50%}.crossing-off__dash--bottom{bottom:50%}.crossing-off__dash--bottom:after{content:\"\";background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 50% no-repeat;width:2rem;height:2rem;position:absolute;bottom:-1.6666666667rem;left:-1rem}.crossing-off__illustration{margin:0 auto;display:block}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
